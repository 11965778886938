import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  useAddProcedureDefinitionsToTestSequence,
  useCreateTestSequence,
} from "../../projectManagementMutations";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Select, TextField } from "formik-material-ui";
import ProcedureDefinitionsPicker from "./ProcedureDefinitions/ProcedureDefinitionsPicker";
import { ProcedureExecutionOrder } from "../../types/ProcedureExecutionOrder";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTestSequenceDefinitionsDispositions } from "../../../common/services/dispositionServices";
import {
  useGroupsByGroupType,
  useUnitTypeFamilies,
} from "../../projectManagementQueries";
import { useTestSequenceDefinition } from "../../testSequenceDefinitionQueries";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  useRemoveProcedureDefinitionsFromTestSequence,
  useUpdateTestSequence,
} from "../../projectManagementMutations";
import { LSDBModal, useLSDBModalState } from "../../../common/LSDBModal";
import { WorkOrderTestSequenceTraveler } from "../customerDetails/workOrders/WorkOrderTestSequenceTraveler";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TSDForm from "./TSDForm";
import LSDBColorPicker from "../../../common/LSDBColorPicker";
import {
  addProcedureDefinitionToList,
  removeProcedureDefinitionFromList,
} from "./ProcedureDefinitions/helpers";

const TSDTemplate = ({ tsdId }: any)  => {
  const history = useHistory();  
  const {
    openModal: isOpenModal,
    setOpenModal,
    handleModalAction,
  } = useLSDBModalState();
  const [selectedProcedureDefinitions, setSelectedProcedureDefinitions] =
  React.useState<ProcedureExecutionOrder[]>([]);
  const [tsdHexColor, setTsdHexColor] = React.useState("#000000");
  const { mutateAsync: createTestSequence } = useCreateTestSequence();
  const { mutateAsync: addProcedureDefinitionsToTestSequence } =
    useAddProcedureDefinitionsToTestSequence();

    const addProcedureDefinitionToSelectedList = (
      procedureDefinition: ProcedureExecutionOrder
    ) => {
      setSelectedProcedureDefinitions((prevState) =>
        addProcedureDefinitionToList(prevState, procedureDefinition)
      );
    };

    const removeProcedureDefinitionFromSelectedList = (
      procedureDefinition: ProcedureExecutionOrder
    ) => {
      setSelectedProcedureDefinitions((prevState) =>
        removeProcedureDefinitionFromList(prevState, procedureDefinition)
      );
    };

    const editProcedureDefinitionInSelectedList = (
      procedureDefinitionToEdit: ProcedureExecutionOrder,
      newProcedureDefinition: ProcedureExecutionOrder
    ) => {
      setSelectedProcedureDefinitions((prevState) =>
        addProcedureDefinitionToList(
          removeProcedureDefinitionFromList(prevState, procedureDefinitionToEdit),
          newProcedureDefinition
        )
      );
    };
  

  const {
    data: testSequence,
    error: testSequenceError,
    isError: isErrorTestSequence,
    isLoading: isLoadingTestSequence,
  } = useTestSequenceDefinition(tsdId);

  React.useEffect(() => {
    if (
      testSequence &&
      testSequence.procedure_definitions &&
      testSequence.procedure_definitions.length !== 0
    ) {
      const procedureDefinitions: ProcedureExecutionOrder[] =
        testSequence.procedure_definitions.map((procedureDefinition: any) => {
          const procedureExecutionOrder: ProcedureExecutionOrder = {
            allow_skip: procedureDefinition.allow_skip,
            execution_group_name: procedureDefinition.execution_group_name,
            execution_group_number: procedureDefinition.execution_group_number,
            execution_condition: procedureDefinition.execution_condition,
            procedure_definition: procedureDefinition.procedure_definition.id,
            procedure_definition_name:
              procedureDefinition.procedure_definition.name,
          };

          return procedureExecutionOrder;
        });

      setTsdHexColor(testSequence.hex_color);
      setSelectedProcedureDefinitions(procedureDefinitions);
    }
  }, [testSequence]);

  const handleColorChange = (color: string) => {
    setTsdHexColor(color);
  };

  // const addProcedureDefinitionToSelected = async (
  //   procedureDefinition: ProcedureExecutionOrder
  // ) => {
  //   try {
  //     await addProcedureDefinitionToTestSequence({
  //       testSequenceId: tsdId,
  //       procedureDefinitions: [procedureDefinition],
  //     });

  //     setSelectedProcedureDefinitions((prevState) =>
  //       addProcedureDefinitionToList(prevState, procedureDefinition)
  //     );

  //     toast.success("Added Procedure Definition to Test Sequence");
  //   } catch (e) {
  //     toast.error("Error adding Procedure Definition to Test Sequence");
  //   }
  // };

  // const removeProcedureDefinitionFromSelected = async (
  //   procedureDefinition: ProcedureExecutionOrder
  // ) => {
  //   try {
  //     await removeProceduresFromTestSequence({
  //       testSequenceId: tsdId,
  //       procedureDefinitions: [procedureDefinition],
  //     });

  //     await setSelectedProcedureDefinitions((prevState) =>
  //       removeProcedureDefinitionFromList(prevState, procedureDefinition)
  //     );
  //     toast.success("Deleted Procedure Definition");
  //   } catch (e) {
  //     toast.error("Error while deleting Procedure Definition");
  //   }
  // };

  // const editProcedureDefinitionInSelected = async (
  //   procedureDefinitionToEdit: ProcedureExecutionOrder,
  //   newProcedureDefinition: ProcedureExecutionOrder
  // ) => {
  //   try {
  //     await removeProceduresFromTestSequence({
  //       testSequenceId: tsdId,
  //       procedureDefinitions: [procedureDefinitionToEdit],
  //     });

  //     await addProcedureDefinitionToTestSequence({
  //       testSequenceId: tsdId,
  //       procedureDefinitions: [newProcedureDefinition],
  //     });

  //     await setSelectedProcedureDefinitions((prevState) =>
  //       addProcedureDefinitionToList(
  //         removeProcedureDefinitionFromList(
  //           prevState,
  //           procedureDefinitionToEdit
  //         ),
  //         newProcedureDefinition
  //       )
  //     );
  //     toast.success("Edited Procedure Definition");
  //   } catch (e) {
  //     toast.error("Error while editing Procedure Definition");
  //   }
  // };

  return (
    <React.Fragment>
   

      {testSequence && (
        <Container >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >  
            <Grid item xs={12}>
              <TSDForm
                initialValues={{
                  name: testSequence.name+"_New",
                  shortName: testSequence.short_name+"_New",
                  description: testSequence.description,
                  version: testSequence.version,
                  disposition: testSequence.disposition,
                  group: testSequence.group,
                  unitTypeFamily: testSequence.unit_type_family,
                }}
                submitForm={async (values) => {

                  try {
                    await createTestSequence(
                      {
                        name: values.name,
                        short_name: values.shortName,
                        description: values.description,
                        disposition: values.disposition,
                        version: values.version,
                        group: values.group,
                        unit_type_family: values.unitTypeFamily,
                        hex_color: tsdHexColor,
                      },
                      {
                        onSuccess: async ({ id }) => {
                          toast.success(`Created test sequence ${id}`);
  
                          if (selectedProcedureDefinitions.length !== 0) {
                            try {
                              await addProcedureDefinitionsToTestSequence(
                                {
                                  testSequenceId: id,
                                  procedureDefinitions:
                                    selectedProcedureDefinitions,
                                },
                                {
                                  onSuccess: () => {
                                    toast.success(
                                      "Added selected procedure definitions to test sequence"
                                    );
                                  },
                                }
                              );
                            } catch (err) {
                              toast.error(
                                "Failed to add procedure definition to test sequence"
                              );
                            }
                          }
  
                          history.push(`${id}`);
                        },
                      }
                    );
                  } catch (e) {
                    toast.error("Failed to create Test Sequence Definition");
                  }



                  // const { shortName, unitTypeFamily, ...pickedValues } = values;

                  // const updatedTSD = {
                  //   ...pickedValues,
                  //   short_name: shortName,
                  //   unit_type_family: unitTypeFamily,
                  //   hex_color: tsdHexColor,
                  // };

                  // try {
                  //   const response = await updateTSD({ tsdId, updatedTSD });
                  //   console.log(response);

                  //   toast.success("Successfully updated TSD");
                  //   history.push(`${tsdId}`);
                  // } catch (e) {
                  //   toast.error("Error updating TSD");
                  // }
                }}
                selectedProcedureDefinitionsIsEmpty={
                  selectedProcedureDefinitions.length === 0
                }
              >
                <LSDBColorPicker
                  chipLabel="Test Sequence Definition Color"
                  onChange={handleColorChange}
                  initialHexColor={testSequence.hex_color}
                />
                  <ProcedureDefinitionsPicker
              tsdProcedureDefinitions={selectedProcedureDefinitions}
              onAddItem={(newProcedureDefinition) => {
                console.log("Adding new item to a new TSD");
                console.log({ item: newProcedureDefinition });
                addProcedureDefinitionToSelectedList(newProcedureDefinition);
              }}
              onEditItem={(
                oldProcedureDefinition,
                newProcedureDefinition
              ) => {
                console.log("Editing existing item on a new TSD");
                console.log({
                  oldPD: oldProcedureDefinition,
                  newPD: newProcedureDefinition,
                });
                editProcedureDefinitionInSelectedList(
                  oldProcedureDefinition,
                  newProcedureDefinition
                );
              }}
              onDeleteItem={(procedureDefinitionToDelete) => {
                console.log("Deleting item on a new TSD");
                console.log({ item: procedureDefinitionToDelete });
                removeProcedureDefinitionFromSelectedList(
                  procedureDefinitionToDelete
                );
              }}
            />
                {/* <ProcedureDefinitionsPicker
                  tsdProcedureDefinitions={selectedProcedureDefinitions}
                  onAddItem={async (newProcedureDefinition) => {
                    await addProcedureDefinitionToSelected(
                      newProcedureDefinition
                    );
                  }}
                  onEditItem={async (
                    oldProcedureDefinition,
                    newProcedureDefinition
                  ) => {
                    console.log(`Editing existing item in tsd ${tsdId}`);
                    console.log({
                      existingItem: oldProcedureDefinition,
                      newItem: newProcedureDefinition,
                    });
                    await editProcedureDefinitionInSelected(
                      oldProcedureDefinition,
                      newProcedureDefinition
                    );
                  }}
                  onDeleteItem={async (procedureDefinitionToDelete) => {
                    await removeProcedureDefinitionFromSelected(
                      procedureDefinitionToDelete
                    );
                  }}
                /> */}
              </TSDForm>
            </Grid>
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
};
export default TSDTemplate;
