import { AxiosInstance } from "axios";
import React from "react";
import { useFetchContext } from "../../../common/FetchContext";
import { useQueryClient } from "react-query";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { NoteList } from "../../../common/noteList";
import { urls } from "../../../common/urls";
import { getStepResultNote } from "../../../testCommunication/common/testMutations";
import { formatDate } from "../../../common/formatDate";

export interface Note {
    authAxios: AxiosInstance;
  }
  
  export const StepResultNotes = ({
    id, name
  }: any) => {
    const [isOpen, setOpen] = React.useState<boolean>(false);

    const {
        data: stepResultNote,
        error: stepResultNoteError,
        isLoading: isLoadingstepResultNote,
        isError: isErrorstepResultNote,
        isSuccess: isSuccessstepResultNote,
      } = getStepResultNote(id);
  

      const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
   
  
    if (isLoadingstepResultNote ) {
      return (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
      );
    }

    if(isErrorstepResultNote){
        return(
            <>
            </>
        )
    }
    let item = 1;


    return (
        <>
          <Button variant="contained" color="primary" fullWidth onClick={handleOpen}>
            view
          </Button>
          <Dialog onClose={handleClose} open={isOpen}>
            <DialogContent>
              <p></p>
              {stepResultNote ? (
                <List>
                  {stepResultNote.map((unitQueued: any, index: any) => (
                    
                    <>
                      <ListItem>
                        <ListItemText
                  
                          primary={unitQueued.step_result_name+"   "+(item++)}
                          secondary={
                            <>
                              <p>{`Date Time : ${unitQueued.datetime}`}</p>
                              <p>{`Asset Name : ${unitQueued.asset_name&&unitQueued.asset_name}`}</p>
                              {unitQueued.notes&&<p>{`Note : ${unitQueued.notes}`}</p>}
                              <p>{`User: ${unitQueued.username}`}</p>

                            </>
                          }
                        />
                      </ListItem>
    
                      <Divider />
                    </>
                  ))}
                </List>
              ) : (
                <Typography variant="h6">
                  No data available
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
  
  };