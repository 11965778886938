import React from "react";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import { Button, Paper,  Switch,  Table,  TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";

export const ScannedTable = ({data}:any)=>{

      return (
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Serial Number</TableCell>
              <TableCell align="center">Module Type</TableCell>
              <TableCell align="center">Status</TableCell>

              <TableCell align="center">Test Type</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {data.length>0&&
              data.map((item: { serial_number: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; module_type: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; test_sequence_name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: any) => (
              <TableRow>
                <TableCell component="th" scope="row" align="center">{item.serial_number}</TableCell>
                <TableCell align="center">{item.module_type}</TableCell>
                {/* <TableCell align="center">
                <Select
                fullWidth>
                {item.test_type?.map((type: any) => (
            <MenuItem
              key={type.id}
              value={type.id}
            >
              {type.name}
            </MenuItem>
          ))}
                </Select>
                </TableCell> */}
                <TableCell align="center" color="primary"><Switch  defaultChecked /></TableCell>
                <TableCell align="center" color="primary">{item.test_sequence_name?item.test_sequence_name:"Not Assigned"}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      );
    
}

