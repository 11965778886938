import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, colors, styled } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom";
import { useModuleInfo, useModuleUpdate, useUpdateModulefiles } from "../../../intake/intakeQueries";
import { ReactChild, ReactFragment, ReactPortal } from "react";
import { Key } from "react";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { AddAPhotoRounded, Edit } from "@material-ui/icons";
import { LabelButton } from "../../../intake/components/unitIntake/Unitprintbutton";
import { formatDate } from "../../../common/formatDate";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { DatePicker } from "formik-material-ui-pickers";
import React, { useState, useRef } from "react";
import { useFetchContext } from "../../../common/FetchContext";
import { useCrateUpdate } from "../../../intake/intakeMutations";
import { DropzoneArea } from "material-ui-dropzone";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "primary",
  padding: theme.spacing(2),
  fontSize: 14,
  borderRadius: 10,
  color: "secodary",
}));

var moduleInfo;

export const ModuleInfo = () => {

  const { authAxios } = useFetchContext();

  const { id } = useParams() as {
    id: string;
  };

  const [open, setOpen] = React.useState(false)
  const [openImage, setOpenImage] = React.useState(false)
  const [image, setImage] = React.useState("")
  const [files, setFiles] = React.useState([]);
  const [uploading, setUploading] = useState(false);
  const [labelName, setLabel] = React.useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenImage(false);

  };





  const handleImage = async (item: any) => {
    console.log(item)
    setImage(item.id)
    setLabel(item.label_name)
    fileInputRef.current?.click()
   
  };


  function goto(): void {
    history.push(`/operations_management/module_intake/intake/upload ${id}`);
  }


  const { mutateAsync: mutate } = useUpdateModulefiles();

  const { mutateAsync: mutateParam } = useModuleUpdate();



  const handleFileChange = async (uploadedFiles: any) => {
    setFiles(uploadedFiles);
    console.log(uploadedFiles)
    setUploading(true); // Start uploading
    try {
      if (uploadedFiles.length > 0) {
        for (let file of uploadedFiles) {
          setOpenImage(false)
          await mutate({
            authAxios,
            file: file,
            id: id,
            image_id: image,
            label: labelName
          });

        }
        toast('Image uploaded successfully!');
      }
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error('Image uploaded faild!');
      setOpenImage(false)

    }
    finally {
      setUploading(false); // End uploading
      window.location.reload();
    }

  };




  const history = useHistory();

  const {
    error: errorData,
    data: moduleData,
    isLoading: isloding,
    isError: isErrorData,
  } = useModuleInfo(id);





  if (isloding) {
    return (
      <Backdrop open={isloding}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  if (isErrorData) {
    return (
      <ErrorMessage error={"No data found"} />

    );
  }
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles && uploadedFiles.length > 0) {
      handleFileChange(Array.from(uploadedFiles));
    }

  };

  return (
    <Box sx={{ flexGrow: 1 }} padding={2}>
      <Grid container spacing={2} alignItems="flex-start" >
        <Grid item xs={6} alignItems="flex-start"
          direction="column">
          <Box padding={2}>
            <Typography variant="h4" color="primary">Intake Info
              <IconButton onClick={handleClickOpen}><Edit color="primary" /></IconButton>
            </Typography>
          </Box>
          <Item >

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label>Location: {moduleData.module_intake_details && moduleData.module_intake_details.location}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Intake Date: {moduleData.module_intake_details && formatDate(moduleData.module_intake_details.intake_date)}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Received  Date: {moduleData.module_intake_details && formatDate(moduleData.module_intake_details.received_date)}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Intake Id : {moduleData.module_intake_details.moduleintake_id}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Lot Id: {moduleData.module_intake_details.lot_id}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Project Number: {moduleData.module_intake_details.project_number}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Customer Name: {moduleData.module_intake_details.customer_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Manufacturer Name: {moduleData.module_intake_details.manufacturer_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>WorkOrder: {moduleData.module_intake_details.bom}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Crate Name: {moduleData.module_intake_details.crate_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Unit Requied: {moduleData.expected_unit_count}</label>
              </Grid>
            </Grid>
          </Item>

        </Grid>
        <Grid item xs={6} alignItems="flex-start"
          direction="column">
          <Box padding={2.5}>

            <Typography variant="h4" color="primary">Intake Details

            </Typography>
          </Box>
          <Item >
            <Grid container spacing={2}>

              <Grid item xs={6}>
                <label>Total No. of Modules : 12</label>
              </Grid>
              <Grid item xs={6}>
                <label>Module Type: {moduleData.module_intake_details.module_type && moduleData.module_intake_details.module_type}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Cell Type: {moduleData.module_property_details.module_technology_name}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Module Dimensions (mm): {moduleData.module_property_details.module_width}X{moduleData.module_property_details.module_height}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Mpp:  {moduleData.module_property_details.nameplate_pmax}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Voc: {moduleData.module_property_details.voc}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Isc: {moduleData.module_property_details.isc}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Vmp: {moduleData.module_property_details.vmp}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Imp: {moduleData.module_property_details.imp}</label>
              </Grid>
              <Grid item xs={6}>
                <label>Rated Voltage: {moduleData.module_property_details.system_voltage}</label>
              </Grid>
              <Grid item xs={6}>
                <Box padding={1.2}>

                  </Box>
              </Grid>
              <Grid item xs={6}>
                <label> </label>
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>

      <Box padding={3} sx={{ flexGrow: 1 }} >
        <Box padding={2}>

          <Typography variant="h4" color="primary">Pannel Details</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: 'green' }}>
              <TableRow>
                <TableCell align="center" color="green">Serial Number</TableCell>
                <TableCell align="center">Module Type</TableCell>
                <TableCell align="center">Test Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Print</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {moduleData.scanned_pannel_details.map((item: {
                unit_id: any; serial_number: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; module_type: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; test_sequence_name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; status: any;
              }) => (

                <TableRow>
                  <TableCell align="center">{item.serial_number && item.serial_number}</TableCell>
                  <TableCell align="center">{item.module_type && item.module_type}</TableCell>
                  <TableCell align="center">{item.test_sequence_name && item.test_sequence_name}</TableCell>
                  <TableCell align="center">{item.status ? "Active" : "InActive"}</TableCell>
                  <TableCell align="center">{item.unit_id ? <LabelButton unitID={item.unit_id} /> : "Print not Available"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Box>
      <Box padding={3}>
        <Box padding={2}>
        <Typography variant="h4" color="primary">Images  <IconButton  onClick={() => goto()}><AddAPhotoRounded color="primary"></AddAPhotoRounded></IconButton></Typography>
        </Box>
        <div>
          {moduleData?.categories_of_module_images?.map((category: any) => (
            <div key={category.label_name}>
              <h2>{category.label_name}</h2>
              <h4>Description: {category.images[0]?.notes ? category.images[0].notes : 'NA'}</h4>
              <ImageList cols={5}>
                {category.images.map((item: any) => (
                  <ImageListItem key={item.id}>
                    <img
                      srcSet={`${item.image_path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.image_path}?w=248&fit=crop&auto=format`}
                      loading="lazy"
                      alt={item.label_name}
                    />
                   
                    <ImageListItemBar
                      actionIcon={
                        <IconButton onClick={() => handleImage(item)}>
                          <Edit color="primary" />
                          {uploading ? image===item.id ? <CircularProgress size={24} />:'':''}
                          </IconButton>
                        
                      }
                      />
                          <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*,.pdf"
                            
                            onChange={handleImageUpload}
                          />
                          {/* Conditional rendering based on uploading state */}
                         
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          ))}
        </div>
      </Box>


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Crate Info</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              intake_date: moduleData.module_intake_details.intake_date,
              received_date: moduleData.module_intake_details.received_date

            }}

            onSubmit={async (values, { setSubmitting }) => {

              try {

                var date = ""
                if (values.intake_date == null) {
                  date = ""
                } else {
                  date = moment(values.intake_date).format("YYYY-MM-DD")
                }
                await mutateParam({
                  authAxios,
                  moduleintake: id,
                  moduleintake_date: values.intake_date,
                  received_date: values.received_date

                });

                setSubmitting(false);

                handleClose();
                toast.success("Project was succesfully changed");
                window.location.reload();

              } catch (error) {
                toast.error("error data");
              }

            }}
          >
            {({ errors, touched, submitForm, resetForm }) => (
              <Form>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item sm={12}>
                    <Field
                      id="intake_date"
                      name="intake_date"
                      component={DatePicker}
                      format="YYYY-MM-DD HH:MM"
                      style={{ marginBottom: 32 }}
                      label="Intake Date"
                      inputVariant="outlined"
                      data-testid="intake_date"
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Field
                      id="received_date"
                      name="received_date"
                      component={DatePicker}
                      format="YYYY-MM-DD HH:MM"
                      style={{ marginBottom: 32 }}
                      label="Received Date"
                      inputVariant="outlined"
                      data-testid="received_date"
                      fullWidth
                    />
                  </Grid>

                  <Button
                    data-testid="submitCrateId"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={submitForm}
                  >
                    Submit
                  </Button>

                </Grid>

              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openImage} onClose={handleClose}>
        <DialogTitle>Image Upload</DialogTitle>
        <DialogContent>
          <DropzoneArea
            acceptedFiles={["image/*", ".pdf"]}
            filesLimit={1}
            maxFileSize={12000000}
            data-testid="fileUploadCrate"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}