import { useFetchContext } from "../common/FetchContext";
import { useMutation, useQuery } from "react-query";
import { Customer } from "../common/types/customer.type";
import { Disposition } from "../common/types/disposition.type";
import { AxiosError, AxiosInstance } from "axios";
import { urls } from "../common/urls";

export interface Crate {
  id: number;
  url: string;
  name: string;
  disposition_name: string;
  shipped_by_name: string;
}

interface ProjectManager {
  administrationComment: string;
  email: string;
  firstName: string;
  id: string;
  isPermanentUser: boolean;
  lastname: string;
  notes: string;
  registrationComment: string;
}

export interface Project {
  customer: Customer;
  disposition: Disposition;
  id: string;
  projectId: string;
  projectManager: ProjectManager;
  projectManagerId: string;
  startDate: string;
  totalUnits: number;
  unitsReceived: number;
}


interface PostCrateFileProps {
  authAxios: AxiosInstance;
  file: File;
  id:string;
  image_id:string;
  label:string;
}


export const moduleInsert = async ({
  authAxios,
  lot_id,
  bom,
  module_type,
  number_of_modules,
  location,
  projects,
  customer,
  newcrateintake,
  intake_date,
  received_date,


}: any): Promise<any> => {
  const { data } = await authAxios.post(urls.intake.intakeSubmit, {
    lot_id:lot_id,
    bom:bom,
    module_type:module_type,
    number_of_modules:number_of_modules,
    location:location,
    projects:projects,
    customer:customer,
    newcrateintake:newcrateintake,
    intake_date,
    received_date,
  });
  return data;
};

export const useModuleInsert = () => {
  return useMutation(moduleInsert);
};


export const modulePannelInsert = async ({
  authAxios,
  serial_number,
  test_type,
  status,
  module_intake,
  

}: any): Promise<any> => {
  const { data } = await authAxios.post(urls.intake.intakePannelSubmit, {
    serial_number,
    test_type,
    status,
    module_intake,
  });
  return data;
};

export const useModulePannelInsert = () => {
  return useMutation(modulePannelInsert);
};


export const modulePannelBulkInsert = async ({
  authAxios,
  scannedpanels,
  module_intake,
}: any): Promise<any> => {
  const { data } = await authAxios.post(urls.intake.intakePannelBulkSubmit, {
    scannedpanels,
    module_intake,
  });
  return data;
};

export const useModulePannelBulkInsert = () => {
  return useMutation(modulePannelBulkInsert);
};

export const useCrates = () => {
  const { authAxios } = useFetchContext();

  const getCrates = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.crates.all);
    return data.results;
  };
  return useQuery("crates", getCrates);
};


export const useModuleIntake = () => {
  const { authAxios } = useFetchContext();

  const getIntakeList = async (): Promise<any> => {
    const { data } = await authAxios.get(
      urls.intake.all
    );
    return data.results;
  };
  return useQuery("intake_list", getIntakeList);

};

export const useModuleIntakeInfo = (id: string) => {
  const { authAxios } = useFetchContext();

  const getIntakeInfo = async (): Promise<any> => {
    const { data } = await authAxios.get(
      urls.intake.intakeInfo(id)
    );
    return data;
  };
  return useQuery("intake_info", getIntakeInfo);

};

export const useModuleIntakeTest = (id: string,bom:string) => {
  const { authAxios } = useFetchContext();

  const getModuleTest = async (): Promise<any> => {
    const { data } = await authAxios.get(
      urls.intake.testType(id,bom)
    );
    return data;
  };
  return useQuery("moduleTest", getModuleTest);

};

export const useLocations = () => {
  const { authAxios } = useFetchContext();

  const getLocations = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.locations.all);
    return data.results;
  };

  return useQuery("locations", getLocations);
};




export const useCrateDetails = (
  id: string,
  customers: any,
  dispositions: any
) => {
  const { authAxios } = useFetchContext();

  const getCrateDetails = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.crates.details(id));
    return data;
  };
  return useQuery(
    ["crateDetails", id, customers, dispositions],
    getCrateDetails,
    {
      enabled:
        customers !== undefined &&
        customers !== null &&
        dispositions !== undefined &&
        dispositions !== null,
    }
  );
};

export const useCrateNotes = (id: string, type: number | null) => {
  const { authAxios } = useFetchContext();

  const getCrateNotes = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.crates.notes(id, type));
    return data;
  };
  return useQuery(["crateNotes", id], getCrateNotes, {
    enabled: id !== undefined && id !== null,
  });
};

export const useProjects = () => {
  const { authAxios } = useFetchContext();

  const getProjects = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.workOrders.intakeUnits);
    return data;
  };
  return useQuery<any, AxiosError>("projects", getProjects);
};

export const useProjectDetails = (projectId: any) => {
  const { authAxios } = useFetchContext();

  const getProjectDetails = async (): Promise<any> => {
    const { data } = await authAxios.get(
      urls.projects.projectDetails(projectId)
    );
    return data;
  };
  return useQuery<any, AxiosError>(
    ["project_details", projectId],
    getProjectDetails
  );
};

export const useWorkOrderDetails = (workOrderId: any) => {
  const { authAxios } = useFetchContext();

  const getWorkOrderDetails = async (): Promise<any> => {
    const { data } = await authAxios.get(
      urls.workOrders.expectedUnitTypes(workOrderId)
    );
    return data;
  };
  return useQuery<any, AxiosError>(
    ["workOrderDetails", workOrderId],
    getWorkOrderDetails
  );
};

export const useWorkOrder = (workOrderId: any) => {
  const { authAxios } = useFetchContext();

  const getWorkOrder = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.workOrders.details(workOrderId));
    return data;
  };
  return useQuery<any, AxiosError>(["workOrder", workOrderId], getWorkOrder);
};

export const useExpectedUnitDetails = (expectedUnitId: any) => {
  const { authAxios } = useFetchContext();
  const getExpectedUnitDetails = async (): Promise<any> => {
    const { data } = await authAxios.get(
      urls.expectedUnitTypes.details(expectedUnitId)
    );
    return data;
  };
  return useQuery<any, AxiosError>(
    ["expectedUnitDetails", expectedUnitId],
    getExpectedUnitDetails,
    {
      enabled:
        expectedUnitId !== undefined &&
        expectedUnitId !== null &&
        expectedUnitId !== "",
    }
  );
};



export const useMagicLink = (id: any) => {
  const { authAxios } = useFetchContext();

  const getMagicLink = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.units.magicLink(id));
    return data;
  };
  return useQuery<any, AxiosError>(["magicLink", id], getMagicLink, {
    enabled: id !== undefined && id !== null,
  });
};

export const useLabel = (id: any, token: any) => {
  const { authAxios } = useFetchContext();

  const getLabel = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.units.label(id, token));
    return data;
  };

  return useQuery<any, AxiosError>(["newLabel", id, token], getLabel, {
    enabled: false,
  });
};


export const useProjectData = () => {
  const { authAxios } = useFetchContext();

  const getProjectData = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.intake.locationData);
    return data;
  };
  return useQuery("crates", getProjectData);
};

export const useModuleInfo = (id:string) => {
  const { authAxios } = useFetchContext();

  const getModuleInfo = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.intake.view(id));
    return data;
  };
  return useQuery("module_data", getModuleInfo);
};


export const moduleUpdate = async (params: any): Promise<any> => {

  const { authAxios, ...postParams } = params;
  const { data } = await authAxios.put(urls.intake.update, {
    ...postParams,
  });
  return data;
};

export const useModuleUpdate = () => {
  return useMutation(moduleUpdate);
};


export const updateModuleFile = async ({
  authAxios,
  file,
  id,
  image_id,
  label,
}: PostCrateFileProps): Promise<any> => {
  try {
    const formData = new FormData();

    formData.append("image_path", file, file.name);
    formData.append("moduleintake", id);
    formData.append("id", image_id);
    formData.append("label_name", label)

    const { data } = await authAxios.put(`module_update/update_image/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (err) {
    const error =
      err.response.data instanceof Array
        ? err.response.data.join()
        : err.response.data;
    throw new Error(`Error while uploading file(s):${error}`);
  }
};

export const useUpdateModulefiles = () => {
  return useMutation(updateModuleFile);
};


export const useModuleInventory = () => {
  const { authAxios } = useFetchContext();

  const getModuleInventory = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.intake.moduleInventory);
    return data;
  };
  return useQuery("module_data", getModuleInventory);
};

export const useModuleInventoryUnit = (serial_number:string) => {
  const { authAxios } = useFetchContext();

  const getModuleInventoryUnit = async (): Promise<any> => {
    const { data } = await authAxios.get(urls.intake.moduleInventoryUnit(serial_number));
    return data;
  };
  return useQuery("data", getModuleInventoryUnit);
};


export const inventoryUpdate = async (params: any): Promise<any> => {

  const { authAxios, serial_number, ...postParams } = params;
  const { data } = await authAxios.patch(urls.intake.moduleInventoryUnit(serial_number), {
    ...postParams,
  });
  return data;
};

export const useInventoryUpdate = () => {
  return useMutation(inventoryUpdate);
};



